import React, { useState } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthorizationProvider } from '../../security/AuthorizationContext';
import ThemeProvider from '../components/Theme/ThemeProvider';
import { AuthenticationProvider } from '../common/authentication/AuthenticationContext';
import initApollo from '../../../services/initApollo';
import useDocumentTitle from '../common/useDocumentTitle';
import { Theme } from '../components/Theme';
import AppRouteSwitch from './routing/AppRouteSwitch';

const App: React.FC = () => {
  const [apolloClient] = useState(initApollo({}));
  useDocumentTitle('Crisp User Linking');

  return (
    <div>
      <AuthenticationProvider>
        <ApolloProvider client={apolloClient}>
          <AuthorizationProvider>
            <ThemeProvider theme={Theme.Lettuce}>
              <BrowserRouter>
                <AppRouteSwitch />
              </BrowserRouter>
            </ThemeProvider>
          </AuthorizationProvider>
        </ApolloProvider>
      </AuthenticationProvider>
    </div>
  );
};

export default withLDProvider({
  clientSideID: '5d7970f8c6a4a007ae338556',
})(App);

import React from 'react';
import ReactDOM from 'react-dom/client';
import './utils/wdyr';
import './animations.css';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import setup from './setup';
import UnfiEmbeddedApp from './components/UnfiEmbeddedApp';
import PartnerMiniSiteApp from './components/lettuce/partner/PartnerMiniSiteApp';
import CrispApp from './components/lettuce/crisp/CrispApp';
import PartnerMiniSiteDemoApp from './components/lettuce/partner/PartnerMiniSiteDemoApp';
import UserLinkingApp from './components/lettuce/userLinking/UserLinkingApp';

setup();

const isUnfiInsights = (pathname: string) =>
  pathname.startsWith('/unfi-insights-embedded') || pathname.startsWith('/unfi-fuel-embedded');
const isUserLinking = (pathname: string) => pathname.startsWith('/link-user');
const isPartnerMiniSite = (pathname: string) => pathname === '/p' || pathname.startsWith('/p/');
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Convenience routine to allow switching between modes by URL parameter
// Use:
// - localhost:3000/p/dot?demo to enable the demo partner site;
// - localhost:3000/p/dot?demo=false to disable the demo and use the partner authentication
const partnerMiniSiteDemoSwitch = () => {
  const DEMO_PARAM = 'demo';
  if (isPartnerMiniSite(window.location.pathname)) {
    const url = new URL(window.location.href);
    if (url.searchParams.has(DEMO_PARAM)) {
      const demo = url.searchParams.get(DEMO_PARAM);
      CrispApp.partnerMiniSite.setDemoSiteEnabled(demo !== 'false');
      url.searchParams.delete(DEMO_PARAM);
      window.history.replaceState(null, '', url.toString());
    }
  }
};

partnerMiniSiteDemoSwitch();

const AppSwitch = () => {
  const pathname = window.location.pathname;

  switch (true) {
    case isPartnerMiniSite(pathname): {
      if (CrispApp.partnerMiniSite.isDemoSiteEnabled()) {
        return <PartnerMiniSiteDemoApp />;
      }
      return <PartnerMiniSiteApp />;
    }
    case isUnfiInsights(pathname):
      return <UnfiEmbeddedApp />;
    case isUserLinking(pathname):
      return <UserLinkingApp />;
    default:
      return <App />;
  }
};

root.render(
  <React.StrictMode>
    <AppSwitch />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

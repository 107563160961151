import React, { ReactNode, Suspense } from 'react';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';

type PartnerTemplateProps<T extends object> = {
  children?: ReactNode;
  Layout?: 'div' | React.FC<T>;
};

function UserLinkingTemplate<T extends object>({
  children,
  Layout = MaxWidthLayout as React.FC<T>,
  ...props
}: PartnerTemplateProps<T>) {
  const layoutProps = props as T;
  return (
    <main id="main">
      <Layout {...layoutProps}>
        <Suspense>{children}</Suspense>
      </Layout>
    </main>
  );
}

export default UserLinkingTemplate;

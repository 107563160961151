import React from 'react';
import { lazyWithRetry } from '../../../lib';
import UserLinkingTemplate from '../templates/UserLinkingTemplate';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';

const RenderHome = lazyWithRetry(() => import(/* webpackChunkName: "link-user-home" */ '../Home'));

const HomePage: React.FC = ({}) => {
  return (
    <UserLinkingTemplate Layout={MaxWidthLayout}>
      <RenderHome />
    </UserLinkingTemplate>
  );
};

export default HomePage;

import React from 'react';
import { Route } from 'react-router';
import RouteSwitch from '../../components/routing/RouteSwitch';
import { RouteOptions } from '../../components/routing/lib';
import { Routes } from '../routing/Routes';
import NotFound from '../../components/routing/NotFound';

const NotFoundRoutes = (
  <>
    <Route exact path={'*'} component={NotFound} />
  </>
);

// Require authentication by default for all routes. Route declarations have to explicitly set
// requireAuth to false in order to bypass auth checks.
const DefaultRouteOptions: RouteOptions = { requireAuth: true };

const AppRouteSwitch: React.FC = () => (
  <RouteSwitch
    routes={Routes}
    defaultRouteOptions={DefaultRouteOptions}
    additionalRoutes={NotFoundRoutes}
  />
);

export default AppRouteSwitch;

import pathToRegexp from 'path-to-regexp';
import { maybe, Maybe } from '@tellurian/ts-utils';
import {
  encodeRouteParams,
  RouteDeclaration,
  RouteIdToRoute,
  RouteParams,
} from '../../components/routing/lib';
import UserLinkingHomepage from '../pages/HomePage';
import { PartnerApplication } from '../../components/lib/Account';

export const Routes = [
  {
    id: 'UserLinkingHome',
    path: '/link-user/:partner',
    Render: UserLinkingHomepage,
    requireAuth: true,
  },
] as const satisfies RouteDeclaration[];

export type RouteId = (typeof Routes)[number]['id'];

export const RouteById = Routes.reduce<RouteIdToRoute<RouteId>>(
  (acc, r) => ({ ...acc, [r.id]: r }),
  {} as RouteIdToRoute<RouteId>,
);

const PartnerUrlRegExp = /\/link-user\/([^/]+)/;

export const getPartnerApplicationFromPathname = (pathname: string): Maybe<PartnerApplication> => {
  return maybe(PartnerUrlRegExp.exec(pathname)?.[1]) as Maybe<PartnerApplication>;
};

const compilePath = pathToRegexp.compile;
export const path = (routeId: RouteId): ((params?: RouteParams) => string) => {
  return params => compilePath(RouteById[routeId].path)(params && encodeRouteParams(params));
};
